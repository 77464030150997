<template>
  <div>
    <v-row dense justify="space-between">
      <v-col cols="3">
        <v-text-field outlined dense label="Key No."></v-text-field>

        <v-autocomplete
          outlined
          dense
          v-model="record.Series"
          :items="series"
          item-text="name"
          item-value="value"
          label="Deposit Currency"
          class="mt-1"
        ></v-autocomplete>

        <v-text-field
          outlined
          dense
          label="No of Vouchers."
          class="mt-1"
          :append-icon="'mdi-airballoon'"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <DatePicker @date="setDate" :myDate="record.DocDueDate" :title="`Date`"></DatePicker>

        <DatePicker @date="setDate" :myDate="record.DocDueDate" :title="`Display Until`"></DatePicker>

        <v-text-field outlined dense class="mt-1" label="Find Voucher No."></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-data-table :headers="headers"></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      series: [],
      record: [],
      date: null,
      headers: [
        { text: "Date", value: "NumAtCard" },
        { text: "Acct Code", value: "CardName" },
        { text: "Doc No.", value: "" },
        { text: "Card Name", value: "" },
        { text: "Ref", value: "" },
        { text: "Payment Method", value: "" },
        { text: "BP/ Account Code", value: "" },
        { text: "BP/ Account Name", value: "" },
        { text: "Total", value: "" },
      ]
    };
  },
  methods: {
    setDate(date) {
      this.date = date;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>